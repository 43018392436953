import { GET_SALES_ACTIVITY } from "./actions";
import { getContacts } from "./contactActions";
import { firestore } from "../store";
import firebase from "firebase";

export const getSalesActivity = () => async (dispatch) => {
    if (firebase.auth().currentUser !== null) {
        const contacts = await dispatch(getContacts());
        const currentUser = firebase.auth().currentUser.uid;

        const salesActivity = {
            prospects: [],
            intro: [],
            firstMeeting: [],
            rfqReceived: [],
            audit: [],
            awardPending: []
        };

        const latestActivity = [];
        await firestore.collection("salesActivity").get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                contacts.map(contact => {
                    if (doc.id === contact.id
                        && contact.salesperson === currentUser
                        && contact.isProspect) {
                        const data = doc.data();
                        const activity = {
                            ...data,
                            id: doc.id,
                            displayName: contact.displayName,
                            phone: contact.phone,
                            email: contact.email,
                            companyName: contact.companyName
                        }

                        return latestActivity.push(activity);
                    }
                })
            });
        }).then(function () {
            latestActivity.forEach(activity => {
                if (activity.salesStatus === "prospect") {
                    salesActivity.prospects.push(activity);
                } else if (activity.salesStatus === "intro") {
                    salesActivity.intro.push(activity);
                } else if (activity.salesStatus === "firstMeeting") {
                    salesActivity.firstMeeting.push(activity);
                } else if (activity.salesStatus === "rfqReceived") {
                    salesActivity.rfqReceived.push(activity);
                } else if (activity.salesStatus === "audit") {
                    salesActivity.audit.push(activity);
                } else if (activity.salesStatus === "awardPending") {
                    salesActivity.awardPending.push(activity);
                }
            })
        }).then(function () {
            return dispatch({ type: GET_SALES_ACTIVITY, payload: salesActivity });
        })
    }
}

// 👾
// As of 7/10/2019, the "salesActivity" document that has the same id as the prospect is the last activity.
// The way this currently works is the by "copying" the last activity (document with the same id as the prospect)
// and setting the document with the same id as the prospect as the new document.
export const addActivity = (newActivity, prospect) => async (dispatch) => {
    let lastActivity = {};

    if (prospect) {
        await firestore.collection("salesActivity").doc(prospect).get().then(function (doc) {
            lastActivity = doc.data();
            if (doc.exists) {
                firestore.collection("salesActivity").add(lastActivity);
            }
        }).then(function () {
            firestore.collection("salesActivity").doc(prospect).set(newActivity);
        });
    } else {

    }

    // This is being hacked together due to the deadline. This will need to be cleaned up. 🐴  
    dispatch(getSalesActivity())
}