import { ADD_CONTACT, GET_CONTACTS, GET_ALL_CONTACTS } from "../actions/actions";

const initialState = {
    allContacts: [],
    userContacts: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CONTACTS:
            return {
                ...state,
                userContacts: action.payload,
            };
        case GET_ALL_CONTACTS:
            return {
                ...state,
                allContacts: action.payload,
            }
        default:
            return state;
    }
}
