// Compan(y/ies) actions 🏢
export const ADD_COMPANY = "ADD_COMPANY";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_ALL_COMPANIES = "GET_COMPANIES";
export const UPDATE_COMPANY = "UPDATE_COMPANY";

// Contact(s) actions 🙂
export const ADD_CONTACT = "ADD_CONTACT";
export const GET_CONTACTS = "GET_CONTACTS";
export const GET_ALL_CONTACTS = "GET_ALL_CONTACTS";
export const UPDATE_CONTACT = "UPDATE_CONTACT";

// Sales Activit(y/ies) actions 💵
export const GET_SALES_ACTIVITY = "GET_SALES_ACTIVITY";

// Notifications 💬
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";