import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import { UserIsAuthenticated, UserIsNotAuthenticated } from "./helpers/auth";


import FirebaseConnect from "./components/firebase/FirebaseConnect";
import Login from "./components/auth/Login";
import Navigation from "./components/layout/Navigation";
import Dashboard from "./components/dashboard/Dashboard";
import Contacts from "./components/contacts/Contacts";
import Companies from "./components/companies/Companies";
import ReportsPage from "./components/reports/ReportsPage";

//import AlertDialog from "./components/common/AlertDialog";
import SnackbarNotification from "./components/common/SnackbarNotification";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#5299e8",
      main: "#2780e3",
      dark: "#1b599e",
      contrastText: "#fff"
    },
    secondary: {
      light: "#f73378",
      main: "#f50057",
      dark: "#ab003c",
      contrastText: "#fff"
    }
  },
  typography: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]
  }
});

function App() {
  return (
    <MuiThemeProvider theme={theme} >
      <Router>
        {/* <AlertDialog /> */}
        <Navigation />
        <FirebaseConnect />

        <SnackbarNotification />
        <Switch>
          <Route
            exact
            path="/"
            component={UserIsAuthenticated(Dashboard)}
          />
          <Route
            exact
            path="/dashboard"
            component={UserIsAuthenticated(Dashboard)}
          />
          <Route
            exact
            path="/contacts"
            component={UserIsAuthenticated(Contacts)}
          />
          <Route
            exact
            path="/companies"
            component={UserIsAuthenticated(Companies)}
          />
          <Route
            exact
            path="/reports"
            component={UserIsAuthenticated(ReportsPage)}
          />
          <Route
            exact
            path="/Login"
            component={UserIsNotAuthenticated(Login)}
          />
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
