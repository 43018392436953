import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonBase from "@material-ui/core/ButtonBase";

import { salesStatuses } from "../sales/salesStatuses";

class IntroArrow extends Component {
  state = {
    activities: []
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      const { activities } = this.props;

      this.setState({ activities });
    }
  }

  onClick = e => {
    e.preventDefault();
    // this.props.onClick("Intro", this.state.activities);
    this.props.onClick("Intro", salesStatuses.INTRO)
  };

  render() {
    return (
      <div className="arrow-container">
        <div className="arrow-btn arrow-intro">
          <ButtonBase onClick={this.onClick}>
            <span className="arrow-btn-label">
              {this.state.activities.length} <br />
              Intro
            </span>
          </ButtonBase>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    activities: state.salesActivity.intro
  }
}

export default connect(mapStateToProps)(IntroArrow);
