import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import Card from "../common/Card";
import ConfirmDialog from "../common/ConfirmDialog";

class CompanyDetails extends Component {
  state = {
    company: {
      salesperson: null,
      name: null,
      email: null,
      phone: null,
      streetAddress: null,
      suiteNumber: null,
      city: null,
      state: null,
      zipCode: null,
      country: null,
      note: null
    },

    showConfirmDelete: false,
    updatingCompany: undefined
  };

  componentDidUpdate(prevProps) {
    if (prevProps.company !== this.props.company) {
      this.setState({
        company: this.props.company,
        updatingCompany: this.props.updatingCompany
      });
    }
  }

  /* On Change */
  onChange = e => {
    this.setState({
      company: {
        ...this.state.company,
        [e.target.name]: e.target.value
      }
    });
  };

  /* On Delete */
  onDelete = () => {
    this.setState({ showConfirmDelete: true });
  };

  /* Dialog functions */
  onDialogOk = () => {
    this.props.onDelete(this.state.company);
  };

  onDialogCancel = () => {
    this.setState({ showConfirmDelete: false });
  };

  /* On Save */
  onSave = e => {
    e.preventDefault();
    const { company } = this.state;

    this.props.onSave(company);
  };

  render() {
    return (
      <Fragment>
        {/* Conditionally render the confirm delete dialog */}
        {this.state.showConfirmDelete ? (
          <ConfirmDialog
            onCancel={this.onDialogCancel}
            onOk={this.onDialogOk}
          />
        ) : null}

        <Card
          title={
            this.state.updatingCompany ? this.state.company.name : "New Company"
          }
        >
          <div className="container">
            <form onSubmit={this.onSave}>
              <div className="form-group">
                {/* Salesperson dropdown */}
                {this.props.userRole.role === "admin" ? (
                  <div className="row">
                    <div className="col p-0">
                      <div className="input-group mb-3">
                        <FormControl fullWidth={true} /*required*/>
                          <InputLabel htmlFor="salesperson-dropdown">
                            Salesperson
                          </InputLabel>
                          <Select
                            value={this.state.company.salesperson}
                            onChange={this.onChange}
                            fullWidth={true}
                            inputProps={{
                              name: "salesperson",
                              id: "salesperson-dropdown"
                            }}
                            // required
                          >
                            <MenuItem value="" />
                            {this.props.users.map(user => (
                              <MenuItem key={user.id} value={user.id} active>
                                {user.displayName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* Company name */}
                <div className="row">
                  <div className="col p-0">
                    <TextField
                      required
                      type="text"
                      fullWidth={true}
                      label="Name"
                      autoFocus={true}
                      name="name"
                      value={this.state.company.name}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                {/* Email */}
                <div className="row">
                  <div className="col mt-2 p-0 pr-sm-2">
                    <TextField
                      type="email"
                      fullWidth={true}
                      label="Email"
                      name="email"
                      value={this.state.company.email}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                {/* Phone */}
                <div className="row">
                  <div className="col mt-2 p-0">
                    <TextField
                      type="text"
                      fullWidth={true}
                      label="Phone"
                      name="phone"
                      value={this.state.company.phone}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                {/* Street address */}
                <div className="row">
                  <div className="col mt-2 p-0">
                    <TextField
                      type="text"
                      fullWidth={true}
                      label="Street address"
                      name="streetAddress"
                      value={this.state.company.streetAddress}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                {/* Suite number */}
                <div className="row">
                  <div className="col mt-2 p-0">
                    <TextField
                      type="text"
                      fullWidth={true}
                      label="Suite number, building name, etc..."
                      name="suiteNumber"
                      value={this.state.company.suiteNumber}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                {/* City */}
                <div className="row">
                  <div className="col-md-4 col-sm-12 mt-2 p-0 pr-sm-2">
                    <TextField
                      type="text"
                      fullWidth={true}
                      label="City"
                      name="city"
                      value={this.state.company.city}
                      onChange={this.onChange}
                    />
                  </div>

                  {/* State */}
                  <div className="col-md-2 col-sm-12 mt-2 p-0 pr-sm-2">
                    <TextField
                      type="text"
                      fullWidth={true}
                      label="State"
                      name="state"
                      value={this.state.company.state}
                      onChange={this.onChange}
                    />
                  </div>

                  {/* Zip code */}
                  <div className="col-md-4 col-sm-12 mt-2 p-0 pr-sm-2">
                    <TextField
                      type="text"
                      fullWidth={true}
                      label="Zip code"
                      name="zipCode"
                      value={this.state.company.zipCode}
                      onChange={this.onChange}
                    />
                  </div>

                  {/* Country */}
                  <div className="col-md-2 col-sm-12 mt-2 p-0">
                    <TextField
                      type="text"
                      fullWidth={true}
                      label="Country"
                      name="country"
                      value={this.state.company.country}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                {/* Notes */}
                <div className="row">
                  <div className="col mt-2 p-0">
                    <TextField
                      type="text"
                      multiline={true}
                      fullWidth={true}
                      label="Notes"
                      name="note"
                      value={this.state.company.note}
                      onChange={this.onChange}
                      rows="6"
                    />
                  </div>
                </div>

                {this.state.updatingCompany ? (
                  <div className="float-left my-4">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={this.onDelete}
                    >
                      Delete
                    </Button>
                  </div>
                ) : null}

                <div className="float-right my-4">
                  {/* Cancel button */}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.props.onCancel}
                  >
                    Cancel
                  </Button>

                  {/* Save button */}
                  <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Card>
      </Fragment>
    );
  }
}

CompanyDetails.propTypes = {
  company: PropTypes.object,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default connect(state => ({
  users: state.firestore.ordered.users,
  userRole: state.firestore.data.userRole
}))(CompanyDetails);
