// react
import React, { Component } from "react";
import PropTypes from "prop-types";

// redux
import { compose } from "redux";
import { connect } from "react-redux";

// firestore
import { firestoreConnect } from "react-redux-firebase";

class FirestoreConnect extends Component {
  render() {
    return <div />;
  }
}

FirestoreConnect.propTypes = {
  auth: PropTypes.object.isRequired
};

export default compose(
  connect(state => ({
    auth: state.firebase.auth
  })),
  firestoreConnect(props => [
    { collection: "companies" },
    {
      collection: "companies",
      storeAs: "userCompanies",
      where: [["salesperson", "==", props.auth.uid]]
    },
    {
      collection: "contacts"
    },
    {
      collection: "contacts",
      storeAs: "userContacts",
      where: [["salesperson", "==", props.auth.uid]]
    },
    {
      collection: "contacts",
      storeAs: "userProspects",
      where: [
        ["salesperson", "==", props.auth.uid],
        ["isProspect", "==", true]
      ]
    },
    {
      collection: "users"
    },
    {
      collection: "users",
      storeAs: "user",
      doc: props.auth.uid
    }
  ])
)(FirestoreConnect);
