import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import DetailsToolbar from "./details/DetailsToolbar";
import AddActivityDialog from "./details/AddActivityDialog";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

const columns = [
  {
    name: "id",
    label: "id",
    options: {
      display: "excluded"
    }
  },
  {
    name: "justDone",
    label: "Just Done",
    options: {
      sort: true
    }
  },
  {
    name: "activityType",
    label: "Activity Type",
    options: {
      sort: true
    }
  },
  {
    name: "nextActivityDate",
    label: "Next Action",
    options: {
      sort: true
    }
  },
  {
    name: "nextActivityType",
    label: "Follow Up Type",
    options: {
      sort: true
    }
  },
  {
    name: "createdBy",
    label: "Updated By",
    options: {
      sort: true
    }
  },
  {
    name: "createdAt",
    label: "Updated Date",
    options: {
      sort: true
    }
  },
  {
    name: "created",
    label: "created",
    options: {
      display: "excluded",
      sortDirection: 'des'
    }
  },
];

class Details extends Component {
  state = {
    openDialog: false
  };

  options = {
    selectableRows: "none",
    pagination: true,
    filterType: "none",
    print: false,
    download: false,
    responsive: "scroll",
    onRowClick: this.onRowClick,
    elevation: 1,
    customToolbar: () => {
      return (
        <DetailsToolbar onClick={() => this.setState({ openDialog: true })} />
      );
    }
  };

  render() {
    return (
      <Fragment>
        {this.state.openDialog ? (
          <AddActivityDialog
            target={this.props.target[0]}
            onClose={() => this.setState({ openDialog: false })}
          />
        ) : null}
        <MUIDataTable
          title={this.props.title}
          columns={columns}
          data={this.props.salesActivity}
          options={this.options}
        />
      </Fragment>
    );
  }
}

Details.propTypes = {
  target: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

export default compose(
  firestoreConnect(props => [
    {
      collection: `salesActivity`,
      storeAs: "prospectActivity",
      where: [["prospect", "==", props.target[0]]]
    }
  ]),
  connect(state => ({
    salesActivity: state.firestore.ordered.prospectActivity
  }))
)(Details);
