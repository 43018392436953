import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import CompaniesDrilldownToolbar from "../common/CompaniesDrilldownToolbar";

import { actions } from "../common/Actions";
import AddCompany from "./AddCompany";
import EditCompany from "./EditCompany";

const columns = [
  {
    name: "id",
    label: "id",
    options: {
      display: "excluded"
    }
  },
  {
    name: "name",
    label: "Name",
    options: {
      sort: true,
      sortDirection: 'asc'
    }
  },
  {
    name: "email",
    label: "Email",
    options: {
      sort: true
    }
  },
  {
    name: "phone",
    label: "Phone",
    options: {
      sort: true
    }
  },
  {
    name: "lastModified",
    label: "Last Modified",
    options: {
      sort: true
    }
  }
];

class CompaniesList extends Component {
  state = {
    action: undefined,
    allCompanies: [],
    userCompanies: [],
    showDetails: false,
    editTargetId: undefined,

    showAllCompanies: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.userCompanies) {
        const companies = [];
        this.props.userCompanies.map(company => {
          return companies.push(company);
        });

        this.setState({ userCompanies: companies });
      }

      if (this.props.allCompanies) {
        const companies = [];
        this.props.allCompanies.map(company => {
          return companies.push(company);
        });

        this.setState({ allCompanies: companies });
      }
    }
  }

  openDetails = action => {
    this.setState({ showDetails: true, action });
  };

  closeDetails = () => {
    this.setState({ showDetails: false });
  };

  onRowClick = row => {
    console.log(row[0]);
    this.setState({ editTargetId: row[0] });
    this.openDetails(actions.EDIT);
  };

  options = {
    selectableRows: "none",
    pagination: true,
    filterType: "none",
    print: false,
    download: false,
    responsive: "scroll",
    onRowClick: this.onRowClick,
    elevation: 1,
    customToolbar: () => {
      return (
        <CompaniesDrilldownToolbar
          onCheck={() =>
            this.setState({ showAllCompanies: !this.state.showAllCompanies })
          }
          onClick={() => this.openDetails(actions.ADD)}
        />
      );
    }
  };

  render() {
    const { showDetails } = this.state;

    return (
      <div className="row no-gutters h-100">
        <div className="col-md-6 col-sm-12 h-100">
          <div className="mt-2 mx-2">
            <MUIDataTable
              title={"Companies"}
              data={
                this.state.showAllCompanies
                  ? this.props.allCompanies
                  : this.props.userCompanies
              }
              columns={columns}
              options={this.options}
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12 h-100">
          <div className="mt-2 mx-2">
            {showDetails ? (
              <Fragment>
                {
                  {
                    [actions.ADD]: (
                      <AddCompany
                        closeDetails={this.closeDetails}
                        showAllCompanies={this.state.showAllCompanies}
                      />
                    ),
                    [actions.EDIT]: (
                      <EditCompany
                        closeDetails={this.closeDetails}
                        editTargetId={this.state.editTargetId}
                        showAllCompanies={this.state.showAllCompanies}
                      />
                    )
                  }[this.state.action]
                }
              </Fragment>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  auth: state.firebase.auth,
  userCompanies: state.firestore.ordered.userCompanies,
  allCompanies: state.firestore.ordered.companies,
  userRole: state.firestore.data.userRole
}))(CompaniesList);
