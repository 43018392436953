import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import PropTypes from "prop-types";

import ContactDetails from "./ContactDetails";
import { createNotification } from "../common/createNotification";
import moment from "moment";

import { addActivity } from "../../redux/actions/salesActivityActions";
import { salesStatuses } from "../sales/salesStatuses";

class EditContactDetails extends Component {
  onDelete = () => {
    const { contact, firestore } = this.props;

    firestore
      .delete({ collection: "contacts", doc: contact.id })
      .then(
        createNotification(
          `${contact.firstName} ${contact.lastName} has been deleted.`
        ),
        this.props.closeDetails()
      );
  };

  onSave = updatedContact => {
    const { auth, contact, firestore } = this.props;

    if (updatedContact !== contact) {
      const newContact = updatedContact;
      newContact.lastModified = moment().format("MM/DD/YYYY h:mm:ss a");
      newContact.lastModifiedBy = auth.uid;

      // if the contact is now a prospect, add an activity and mark them as prospect.
      if (updatedContact.isProspect && !contact.isProspect) {
        const newActivity = {
          // Activity
          prospect: contact.id,
          justDone: `${contact.firstName} ${contact.lastName} has been made a prospect.`,
          salesStatus: salesStatuses.PROSPECT,
          createdBy: "System",
          createdAt: moment().format("MM/DD/YYYY"),
          created: moment().format()
        };

        this.props.addActivity(newActivity, contact.id);
      }
      // if the contact was previously a prospect, but is no longer, add an activity for future reference
      else if (!updatedContact.isProspect && contact.isProspect) {
        const newActivity = {
          // Activity
          prospect: contact.id,
          justDone: `${contact.firstName} ${contact.lastName} is no longer a prospect.`,
          createdBy: "System",
          createdAt: moment().format("MM/DD/YYYY"),
          created: moment().format()
        };

        this.props.addActivity(newActivity, contact.id);
      }

      firestore
        .update({ collection: "contacts", doc: contact.id }, newContact)
        .then(
          createNotification(
            `${newContact.firstName} ${newContact.lastName} has been updated.`
          )
        );
    }

    this.props.closeDetails();
  };

  render() {
    return (
      <ContactDetails
        contact={this.props.contact}
        onDelete={this.onDelete}
        onCancel={this.props.closeDetails}
        onSave={this.onSave}
        updatingContact={true}
        showAllCompanies={this.props.showAllCompanies}
      />
    );
  }
}

EditContactDetails.propTypes = {
  firestore: PropTypes.object.isRequired,
  targetContactId: PropTypes.string.isRequired
};

export default compose(
  firestoreConnect(props => [
    {
      collection: "contacts",
      storeAs: "contact",
      doc: props.targetContactId
    }
  ]),
  connect(({ firestore: { ordered } }) => ({
    contact: ordered.contact && ordered.contact[0]
  })),
  connect(state => ({
    auth: state.firebase.auth
  }), { addActivity })
)(EditContactDetails);
