import React, { Component } from "react";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";

export class SnackbarNotification extends Component {
  state = {
    open: true
  };

  componentDidUpdate(prevProps) {
    if (this.props.message !== prevProps.message && !this.state.open) {
      this.setState({ open: true });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return this.props.message ? (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.open}
        autoHideDuration={5000}
        onClose={this.handleClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">{this.props.message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    ) : null;
  }
}

const mapStateToProps = state => {
  return {
    message: state.notification.message
  };
};

export default connect(mapStateToProps)(SnackbarNotification);
