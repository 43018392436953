import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import ProspectsArrow from "../sales/ProspectsArrow";
import IntroArrow from "../sales/IntroArrow";
import FirstMeetingArrow from "../sales/FirstMeetingArrow";
import RFQReceivedArrow from "../sales/RFQReceivedArrow";
import AuditdArrow from "../sales/AuditArrow";
import AwardPending from "../sales/AwardPendingArrow";
import { getSalesActivity } from "../../redux/actions/salesActivityActions";

// import { getContacts } from "../../redux/actions/contactActions";

import Drilldown from "./Drilldown";

class Dashboard extends Component {
  state = {
    showDrilldown: false,
    drilldownData: "",
    drilldownTitle: ""
  };

  componentDidMount() {
    this.props.getSalesActivity();
  }

  onArrowClick = (title, data) => {
    this.setState({
      showDrilldown: true,
      drilldownData: data,
      drilldownTitle: title
    });
  };

  render() {
    return (
      <Fragment>
        <div className="row no-gutters mt-2 mx-2">
          <div className="col">
            <ProspectsArrow onClick={this.onArrowClick} />
          </div>
          <div className="col">
            <IntroArrow onClick={this.onArrowClick} />
          </div>
          <div className="col">
            <FirstMeetingArrow onClick={this.onArrowClick} />
          </div>
          <div className="col">
            <RFQReceivedArrow onClick={this.onArrowClick} />
          </div>
          <div className="col">
            <AuditdArrow onClick={this.onArrowClick} />
          </div>
          <div className="col">
            <AwardPending onClick={this.onArrowClick} />
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col">
            {this.state.showDrilldown ? (
              <Drilldown
                title={this.state.drilldownTitle}
                data={this.state.drilldownData}
              />
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default compose(
  firestoreConnect([
    {
      collection: "salesActivity"
    }
  ]),
  connect(state => ({
    salesActivity: state.firestore.ordered.salesActivity,
  }), { getSalesActivity, /*getContacts*/ })
)(Dashboard);
