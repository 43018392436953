import React, { Component, Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
  iconButton: {}
};

class DetailsToolbar extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Tooltip title={"Add Activity"}>
          <IconButton
            className={classes.iconButton}
            onClick={this.props.onClick}
            color={"primary"}
          >
            <AddIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      </Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles, { name: "DetailsToolbar" })(
  DetailsToolbar
);
