import store from "../../redux/store";
import { CREATE_NOTIFICATION } from "../../redux/actions/actions";

function dispatchNotification(message) {
  return {
    type: CREATE_NOTIFICATION,
    payload: message
  };
}

export function createNotification(message) {
  store.dispatch(dispatchNotification(message));
}
