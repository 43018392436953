import { GET_SALES_ACTIVITY } from "../actions/actions";

const initialState = {
    prospects: [],
    intro: [],
    firstMeeting: [],
    rfqReceived: [],
    audit: [],
    awardPending: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SALES_ACTIVITY:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
