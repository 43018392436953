import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
//import { createNotification } from "../../common/createNotification";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { addActivity } from "../../../redux/actions/salesActivityActions";
import { salesStatuses } from "../../sales/salesStatuses";

import moment from "moment";

class AddActivityDialog extends React.Component {
  state = {
    open: true,

    // Firestore fields
    // id: this.props.activity.id,
    // quote_number: null,
    justDone: null,
    activityType: null,
    salesStatus: null,
    outlook: null,
    projectedCloseDate: null,
    projectedSaleAmount: null,
    nextAction: null,
    nextActivityType: null,
    nextActivityDate: null,
    reasonForDead: null,
    soldDate: null,
    saleAmount: null
  };

  onClose = () => this.props.onClose();

  onSave = e => {
    e.preventDefault();

    const {
      //quote_number,
      justDone,
      activityType,
      salesStatus,
      outlook,
      projectedCloseDate,
      projectedSaleAmount,
      nextAction,
      nextActivityType,
      nextActivityDate,
      reasonForDead,
      soldDate,
      saleAmount
    } = this.state;

    const newActivity = {
      // Activity
      salesperson: this.props.auth.uid,
      prospect: this.props.target,
      justDone,
      activityType,
      salesStatus,
      outlook,
      // The date fields must be formatted to MM/DD/YYYY. To format the date fields, I am using Moment.js
      projectedCloseDate:
        projectedCloseDate !== null
          ? moment(projectedCloseDate.toString()).format("MM/DD/YYYY")
          : null,
      projectedSaleAmount,
      nextAction,
      nextActivityType,
      nextActivityDate:
        nextActivityDate !== null
          ? moment(nextActivityDate.toString()).format("MM/DD/YYYY")
          : null,
      reasonForDead,
      soldDate:
        soldDate !== null
          ? moment(soldDate.toString()).format("MM/DD/YYYY")
          : null,
      saleAmount,
      createdBy: this.props.user.displayName,
      createdById: this.props.auth.uid,
      createdAt: moment().format("MM/DD/YYYY"),
      created: moment().format()
    };

    this.props.addActivity(newActivity, this.props.target);
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  readyToSave = salesStatus => {
    // We don't need to check justDone in state because the <form> will validate that
    const { activityType,
      outlook,
      //projectedCloseDate,
      projectedSaleAmount,
      nextAction,
      nextActivityType,
      nextActivityDate,
      //reasonForDead,
      soldDate,
      saleAmount } = this.state;

    switch (salesStatus) {
      // Next action fields
      case salesStatuses.PROSPECT:
      case salesStatuses.INTRO:
      case salesStatuses.FIRST_MEETING:
        if (activityType &&
          nextAction &&
          nextActivityType &&
          nextActivityDate) {
          return true;
        }
        return false;

      // Projection fields
      case salesStatuses.RFQ_RECEIVED:
      case salesStatuses.AUDIT:
        if (activityType &&
          nextAction &&
          nextActivityType &&
          nextActivityDate &&
          outlook &&
          projectedSaleAmount) {
          return true;
        }
        return false;

      // Sold fields
      case salesStatuses.AWARD_PENDING:
        if (activityType &&
          soldDate &&
          saleAmount) {
          return true;
        }
        return false;
      default:
        return false;
    }
  }

  nextActionFields = () => {
    const { nextAction, nextActivityType, nextActivityDate } = this.state;

    return (
      <Fragment>
        {/* Next action textbox */}
        <div className="mt-3">
          <TextField
            required
            type="text"
            fullWidth={true}
            label="Next action"
            autoFocus={true}
            name="nextAction"
            value={nextAction}
            onChange={this.onChange}
          />
        </div>

        {/* Next activity type */}
        <div className="mt-3">
          <FormControl fullWidth={true} required>
            <InputLabel htmlFor="nextActivityType-dropdown">
              Next activity type
            </InputLabel>
            <Select
              value={nextActivityType}
              onChange={this.onChange}
              fullWidth={true}
              inputProps={{
                name: "nextActivityType",
                id: "nextActivityType-dropdown"
              }}
            >
              <MenuItem value={"Phone"}>Phone</MenuItem>
              <MenuItem value={"Email"}>Email</MenuItem>
              <MenuItem value={"F2F"}>Face to face</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="mt-3">
          <TextField
            label="Next activity date"
            type="date"
            name="nextActivityDate"
            value={nextActivityDate}
            onChange={this.onChange}
            fullWidth={true}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
      </Fragment>
    );
  };

  projectionFields = () => {
    const { outlook, projectedSaleAmount } = this.state;

    return (
      <Fragment>
        <div className="mt-3">
          {/* Outlook */}
          <FormControl fullWidth={true} required>
            <InputLabel htmlFor="outlook-dropdown">Outlook</InputLabel>
            <Select
              value={outlook}
              onChange={this.onChange}
              autoFocus={true}
              fullWidth={true}
              inputProps={{
                name: "outlook",
                id: "outlook-dropdown"
              }}
              required
            >
              <MenuItem value="Rainy">
                <span className="text-danger">
                  <i className="fas fa-cloud-showers-heavy" /> Rainy
                </span>
              </MenuItem>
              <MenuItem value="Cloudy">
                <span className="text-warning">
                  <i className="fas fa-clouds" /> Cloudy
                </span>
              </MenuItem>
              <MenuItem value="Sunny">
                <span className="text-primary">
                  <i className="fas fa-sun" /> Sunny
                </span>
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="mt-3">
          {/* Projected sale amount */}
          <FormControl fullWidth>
            <InputLabel htmlFor="adornment-amount">
              Projected sale amount
            </InputLabel>
            <Input
              value={projectedSaleAmount}
              name="projectedSaleAmount"
              onChange={this.onChange}
              fullWidth={true}
              type="number"
              startAdornment={
                <InputAdornment position="start">
                  <span className="text-success">$</span>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        {this.nextActionFields()}
      </Fragment>
    );
  };

  // deadFields = () => {
  //   const { reasonForDead } = this.state;

  //   return (
  //     <Fragment>
  //       {/* Next action textbox */}
  //       <div className="mt-3">
  //         <TextField
  //           required
  //           type="text"
  //           fullWidth={true}
  //           label="Reason for dead status"
  //           autoFocus={true}
  //           name="reasonForDead"
  //           value={reasonForDead}
  //           onChange={this.onChange}
  //         />
  //       </div>
  //     </Fragment>
  //   );
  // };

  soldFields = () => {
    const { soldDate, saleAmount } = this.state;

    return (
      <Fragment>
        <div className="mt-3">
          {/* Sold date */}
          <TextField
            label="Sold date"
            type="date"
            name="soldDate"
            value={soldDate}
            onChange={this.onChange}
            fullWidth={true}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>

        <div className="mt-3">
          {/* Sale amount */}
          <FormControl fullWidth>
            <InputLabel htmlFor="adornment-amount">Sale amount</InputLabel>
            <Input
              value={saleAmount}
              name="saleAmount"
              onChange={this.onChange}
              fullWidth={true}
              type="number"
              startAdornment={
                <InputAdornment position="start">
                  <span className="text-success">$</span>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      </Fragment>
    );
  };

  render() {
    const { open, justDone, activityType, salesStatus } = this.state;

    return (
      <div>
        <Dialog
          open={open}
          disableBackdropClick={true}
          aria-labelledby="draggable-dialog-title"
          fullWidth={true}
        >
          <DialogTitle id="draggable-dialog-title">Add Activity</DialogTitle>
          <form onSubmit={this.onSave}>
            <DialogContent>
              {/* Just done textbox */}
              <TextField
                required
                type="text"
                fullWidth={true}
                label="Just done"
                autoFocus={true}
                name="justDone"
                value={justDone}
                onChange={this.onChange}
              />

              {/* Activity type dropdown */}
              <div className="mt-3">
                <FormControl fullWidth={true} required>
                  <InputLabel htmlFor="activityType-dropdown">
                    Activity type
                  </InputLabel>
                  <Select
                    value={activityType}
                    onChange={this.onChange}
                    fullWidth={true}
                    inputProps={{
                      name: "activityType",
                      id: "activityType-dropdown"
                    }}
                    required
                  >
                    <MenuItem value={"Phone"}>Phone</MenuItem>
                    <MenuItem value={"Email"}>Email</MenuItem>
                    <MenuItem value={"F2F"}>Face to face</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {/* Sales lead status dropdown */}
              <div className="mt-3">
                <FormControl fullWidth={true} required>
                  <InputLabel htmlFor="salesStatus-dropdown">
                    Sales lead status
                  </InputLabel>
                  <Select
                    value={salesStatus}
                    onChange={this.onChange}
                    fullWidth={true}
                    inputProps={{
                      name: "salesStatus",
                      id: "salesStatus-dropdown"
                    }}
                  >
                    <MenuItem value={salesStatuses.PROSPECT}>Prospect</MenuItem>
                    <MenuItem value={salesStatuses.INTRO}>Intro</MenuItem>
                    <MenuItem value={salesStatuses.FIRST_MEETING}>First Meeting</MenuItem>
                    <MenuItem value={salesStatuses.RFQ_RECEIVED}>RFQ Received</MenuItem>
                    <MenuItem value={salesStatuses.AUDIT}>Audit</MenuItem>
                    <MenuItem value={salesStatuses.AWARD_PENDING}>Award Pending</MenuItem>
                    {/* Will there need to be sold/dead statuses? */}
                  </Select>
                </FormControl>
              </div>
              {
                {
                  [salesStatuses.PROSPECT]: this.nextActionFields(),
                  [salesStatuses.INTRO]: this.nextActionFields(),
                  [salesStatuses.FIRST_MEETING]: this.nextActionFields(),
                  [salesStatuses.RFQ_RECEIVED]: this.projectionFields(),
                  [salesStatuses.AUDIT]: this.projectionFields(),
                  [salesStatuses.AWARD_PENDING]: this.soldFields(),
                  // ["dead"]: this.deadFields()
                }[salesStatus]
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={this.onClose} color="secondary">
                Cancel
              </Button>
              {
                this.readyToSave(salesStatus) ? <Button type="submit" color="primary">Save</Button>
                  : <Button type="submit" color="primary" disabled>Save</Button>
              }

            </DialogActions>
          </form>
        </Dialog>
      </div >
    );
  }
}

AddActivityDialog.propTypes = {
  target: PropTypes.string.isRequired
};

export default compose(
  firestoreConnect(props =>
    [
      {
        collection: "salesActivity",
        doc: props.target,
        storeAs: "lastActivity",
      }
    ]
  ),
  connect(state => ({
    auth: state.firebase.auth,
    user: state.firestore.ordered.user[0],
    lastActivity: state.firestore.ordered.lastActivity
  }), { addActivity })
)(AddActivityDialog);
