import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { firestoreConnect } from "react-redux-firebase";

import CompaniesList from "./CompaniesList";

class Companies extends Component {
  render() {
    return <CompaniesList />;
  }
}

Companies.propTypes = {
  firestore: PropTypes.object.isRequired,
  companies: PropTypes.array
};

export default compose(
  firebaseConnect(),
  firestoreConnect([{ collection: "companies" }]),
  connect(state => ({
    companies: state.firestore.ordered.companies
  }))
)(Companies);
