import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { createNotification } from "../common/createNotification";
import moment from "moment";

import CompanyDetails from "./CompanyDetails";

class AddCompany extends Component {
  onSave = company => {
    const { auth, firestore } = this.props;

    company.createdDate = moment().format();
    company.lastModified = moment().format("MM/DD/YYYY h:mm:ss a");
    company.createdBy = auth.uid;
    company.lastModifiedBy = auth.uid;
    if (company.salesperson == null) {
      company.salesperson = auth.uid;
    }

    // TODO: createdBy and lastModifiedBy
    firestore
      .add({ collection: "companies" }, company)
      .then(
        createNotification(`${company.name} has been added.`),
        this.props.closeDetails()
      );
  };

  render() {
    return (
      <CompanyDetails onCancel={this.props.closeDetails} onSave={this.onSave} />
    );
  }
}

AddCompany.propTypes = {
  firestore: PropTypes.object.isRequired
};

export default compose(
  firestoreConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth,
    userRole: state.firestore.data.userRole.role
  }))
)(AddCompany);
