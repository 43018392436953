import React, { Fragment } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";

const defaultToolbarStyles = {
  iconButton: {}
};

class CompaniesDrilldownToolbar extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        {this.props.userRole !== undefined &&
        this.props.userRole.role === "admin" ? (
          <Tooltip title={"Show all companies"}>
            <Checkbox
              checked={this.props.checked}
              onChange={this.props.onCheck}
              name="showAllCompanies"
              // value="showAllContacts"
              color="primary"
            />
          </Tooltip>
        ) : (
          <Tooltip title={"Show all companies"}>
            <Checkbox
              checked={this.props.checked}
              onChange={this.props.onCheck}
              name="showAllCompanies"
              // value="showAllContacts"
              color="primary"
              disabled
            />
          </Tooltip>
        )}

        <Tooltip title={"Add Company"}>
          <IconButton
            className={classes.iconButton}
            onClick={this.props.onClick}
            color={"primary"}
          >
            <AddIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      </Fragment>
    );
  }
}

CompaniesDrilldownToolbar.propTypes = {
  onCheck: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default compose(
  withStyles(defaultToolbarStyles, { name: "CustomToolbar" }),
  connect(state => ({ userRole: state.firestore.data.userRole }))
)(CompaniesDrilldownToolbar);
