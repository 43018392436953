import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import MUIDataTable from "mui-datatables";

import Details from "./Details";

import { salesStatuses } from "../sales/salesStatuses";

const columns = [
  {
    name: "id",
    label: "id",
    options: {
      display: "excluded"
    }
  },
  {
    name: "nextActivityDate",
    label: "Next action",
    options: {
      sort: true      
    }
  },
  {
    name: "displayName",
    label: "Contact",
    options: {
      sort: true,
      sortDirection: 'asc'
    }
  },
  {
    name: "phone",
    label: "Phone",
    options: {
      sort: true
    }
  },
  {
    name: "email",
    label: "Email",
    options: {
      sort: true
    }
  },
  {
    name: "companyName",
    label: "Company",
    options: {
      sort: true
    }
  }
];

class Drilldown extends Component {
  state = {
    // Drilldown
    tite: "",
    data: [],

    // Details
    showDetails: false,
    detailsTarget: null
  };

  componentDidMount() {
    this.setState({
      data: this.props.data
    })
  }

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate");
    const { data } = this.props;

    if (prevProps !== this.props) {
      this.setState({
        tite: this.props.title,
        data,

        // Hide the 'Details' component when an arrow is clicked
        showDetails: false
      });
    }
  }

  onRowClick = row => {
    this.setState({
      detailsTarget: row,
      showDetails: true
    });
  };

  options = {
    selectableRows: "none",
    pagination: true,
    filterType: "none",
    print: false,
    download: false,
    responsive: "scroll",
    onRowClick: this.onRowClick,
    elevation: 1
  };

  render() {
    const { salesActivity } = this.props;

    return (
      <div className="row no-gutters">
        <div className="col m-2">
          <MUIDataTable
            title={this.props.title}
            columns={columns}
            data={{
              [salesStatuses.PROSPECT]: salesActivity.prospects,
              [salesStatuses.INTRO]: salesActivity.intro,
              [salesStatuses.FIRST_MEETING]: salesActivity.firstMeeting,
              [salesStatuses.RFQ_RECEIVED]: salesActivity.rfqReceived,
              [salesStatuses.AUDIT]: salesActivity.audit,
              [salesStatuses.AWARD_PENDING]: salesActivity.awardPending,
            }[this.props.data]}
            options={this.options}
          />
        </div>
        <div className="col m-2">
          {this.state.showDetails ? (
            <Details
              target={this.state.detailsTarget}
              title={`${this.state.detailsTarget[2]} Activity`}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    salesActivity: state.salesActivity
  }
}

export default connect(mapStateToProps)(Drilldown);

// export default compose(
//   firestoreConnect(),
//   connect(state => ({
//     contacts: state.firestore.ordered.userContacts
//   }))
// )(Drilldown);
