import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import firebase from "firebase";
import "firebase/firestore";
import { reactReduxFirebase, firebaseReducer } from "react-redux-firebase";
import { reduxFirestore, firestoreReducer } from "redux-firestore";
import notificationReducer from "./reducers/notificationReducer";
import contactReducer from "./reducers/contactReducer";
import companyReducer from "./reducers/companyReducer";
import salesActivityReducer from "./reducers/salesActivityReducer";
import thunk from "redux-thunk";

// const firebaseConfig = {
//   apiKey: "AIzaSyBmLrZ5LLc17oqi-9snjjBzO9wfz3GRa6s",
//   authDomain: "pacmanager-king-solutions-dev.firebaseapp.com",
//   databaseURL: "https://pacmanager-king-solutions-dev.firebaseio.com",
//   projectId: "pacmanager-king-solutions-dev",
//   storageBucket: "",
//   messagingSenderId: "365693713954",
//   appId: "1:365693713954:web:6d2e37d25615bb02"
// };

const firebaseConfig = {
  apiKey: "AIzaSyAkCE1yUKXduPwEdn3ztK_6jiOwdpy8dRM",
  authDomain: "pacmanager-king-solutions.firebaseapp.com",
  databaseURL: "https://pacmanager-king-solutions.firebaseio.com",
  projectId: "pacmanager-king-solutions",
  storageBucket: "pacmanager-king-solutions.appspot.com",
  messagingSenderId: "704303542055",
  appId: "1:704303542055:web:d99b352c683c32d6"
};

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true
};

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);
// Initialize firestore
export const firestore = firebase.firestore();

const createStoreWithFirebase = compose(
  applyMiddleware(thunk),
  reactReduxFirebase(firebase, rrfConfig),
  reduxFirestore(firebase)
)(createStore);

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  contacts: contactReducer,
  companies: companyReducer,
  salesActivity: salesActivityReducer,
  notification: notificationReducer
});

// Initial state
const initialState = {};

const store = createStoreWithFirebase(
  rootReducer,
  initialState,
  reactReduxFirebase(firebase, rrfConfig)
  // compose(
  //   reactReduxFirebase(firebase, rrfConfig),
  //   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // )
);

export default store;
