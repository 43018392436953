import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import FirestoreConnect from "./FirestoreConnect";

class FirebaseConnect extends Component {
  render() {
    const { auth } = this.props;

    if (auth.uid !== undefined) {
      return <FirestoreConnect />;
    } else {
      return <div />;
    }
  }
}

export default compose(
  connect(state => ({ auth: state.firebase.auth })),
  firebaseConnect()
)(FirebaseConnect);
