import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";

import Card from "../common/Card";
import ConfirmDialog from "../common/ConfirmDialog";

class ContactDetails extends Component {
  state = {
    contact: {
      // contact fields
      salesperson: null,
      firstName: null,
      lastName: null,
      company: null,
      jobRole: null,
      email: null,
      phone: null,
      note: null,
      isProspect: true,
      // more contact fields
      streetAddress: null,
      suiteNumber: null,
      city: null,
      state: null,
      zipCode: null,
      country: null
    },

    showConfirmDelete: false,
    showMoreFields: false,
    updatingContact: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.contact !== this.props.contact) {
      const { contact } = this.props;
      this.setState({
        contact,
        updatingContact: this.props.updatingContact
      });
    }
  }

  /* On Change Functions */
  onChange = e => {
    this.setState({
      contact: {
        ...this.state.contact,
        [e.target.name]: e.target.value
      }
    });
  };

  onToggle = e => {
    this.setState({
      contact: {
        ...this.state.contact,
        [e.target.name]: e.target.checked
      }
    });
  };

  /* On Delete */
  onDeleteClick = () => {
    this.setState({ showConfirmDelete: true });
  };

  /* Dialog Functions */
  onDialogDeny = () => {
    this.setState({ showConfirmDelete: false });
  };

  onDialogConfirm = () => {
    this.onDialogDeny();
    this.props.onDelete(this.state.contact);
  };

  /* On Save
   * Called from <form onSubmit> in render()
   */
  onSave = e => {
    e.preventDefault();
    const { contact } = this.state;
    // Sets the contact's displayName key to firstName + lastName
    contact.displayName = contact.firstName + " " + contact.lastName;
    // TODO: MAKE THE COMPANY KEY THE COMPANY NAME

    this.props.onSave(contact);
  };

  /* More fields */
  moreFields = function () {
    const {
      // more contact fields
      streetAddress,
      suiteNumber,
      city,
      state,
      zipCode,
      country
    } = this.state.contact;

    return (
      <div>
        {/* Street address */}
        <div className="row">
          <div className="col mt-2 p-0">
            <TextField
              type="text"
              fullWidth={true}
              label="Street address"
              name="streetAddress"
              value={streetAddress}
              onChange={this.onChange}
            />
          </div>
        </div>

        {/* Suite number */}
        <div className="row">
          <div className="col mt-2 p-0">
            <TextField
              type="text"
              fullWidth={true}
              label="Suite number, building name, etc..."
              name="suiteNumber"
              value={suiteNumber}
              onChange={this.onChange}
            />
          </div>
        </div>

        {/* City */}
        <div className="row">
          <div className="col-md-4 col-sm-12 mt-2 p-0 pr-sm-2">
            <TextField
              type="text"
              fullWidth={true}
              label="City"
              name="city"
              value={city}
              onChange={this.onChange}
            />
          </div>

          {/* State */}
          <div className="col-md-2 col-sm-12 mt-2 p-0 pr-sm-2">
            <TextField
              type="text"
              fullWidth={true}
              label="State"
              name="state"
              value={state}
              onChange={this.onChange}
            />
          </div>

          {/* Zip code */}
          <div className="col-md-4 col-sm-12 mt-2 p-0 pr-sm-2">
            <TextField
              type="text"
              fullWidth={true}
              label="Postal code"
              name="zipCode"
              value={zipCode}
              onChange={this.onChange}
            />
          </div>

          {/* Country */}
          <div className="col-md-2 col-sm-12 mt-2 p-0">
            <TextField
              type="text"
              fullWidth={true}
              label="Country"
              name="country"
              value={country}
              onChange={this.onChange}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      // contact fields
      salesperson,
      firstName,
      lastName,
      company,
      jobRole,
      email,
      phone,
      note,
      isProspect
    } = this.state.contact;

    const { updatingContact } = this.state;

    const { allCompanies, userCompanies, showAllCompanies } = this.props;

    // This sorts the user's (or all) companies to display them in alphabetical order in the Company dropdown 
    const allCompaniesSorted = allCompanies.slice().sort((a, b) => a.name.localeCompare(b.name));
    const userCompaniesSorted = userCompanies.slice().sort((a, b) => a.name.localeCompare(b.name));

    return (
      <Fragment>
        {/* Conditionally render the confirm delete dialog */}
        {this.state.showConfirmDelete ? (
          <ConfirmDialog
            onCancel={this.onDialogDeny}
            onOk={this.onDialogConfirm}
          />
        ) : null}

        <Card
          title={updatingContact ? `${firstName} ${lastName}` : "New Contact"}
        >
          <div className="container">
            <form onSubmit={this.onSave}>
              <div className="form-group">
                {/* Salesperson dropdown */}
                {this.props.userRole.role === "admin" ? (
                  <div className="row">
                    <div className="col p-0">
                      <div className="input-group mb-3">
                        <FormControl fullWidth={true} /*required*/>
                          <InputLabel htmlFor="salesperson-dropdown">
                            Salesperson
                          </InputLabel>
                          <Select
                            value={salesperson}
                            onChange={this.onChange}
                            fullWidth={true}
                            inputProps={{
                              name: "salesperson",
                              id: "salesperson-dropdown"
                            }}
                          // required
                          >
                            <MenuItem value="" />
                            {this.props.users.map(user => (
                              <MenuItem key={user.id} value={user.id} active>
                                {user.displayName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* First name textbox */}
                <div className="row">
                  <div className="col-md-6 col-sm-12 p-0 pr-sm-2">
                    <TextField
                      required
                      type="text"
                      fullWidth={true}
                      label="First name"
                      autoFocus={true}
                      name="firstName"
                      value={firstName}
                      onChange={this.onChange}
                    />
                  </div>

                  {/* Last name textbox */}
                  <div className="col-md-6 col-sm-12 p-0">
                    <TextField
                      required
                      type="text"
                      fullWidth={true}
                      label="Last name"
                      name="lastName"
                      value={lastName}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                {/* Company dropdown */}
                <div className="row mt-2">
                  <div className="input-group mb-3 col-md-6 p-0 pr-md-2">
                    <FormControl fullWidth={true} /*required*/>
                      <InputLabel htmlFor="company-dropdown">
                        Company
                      </InputLabel>
                      <Select
                        value={company}
                        onChange={this.onChange}
                        fullWidth={true}
                        inputProps={{
                          name: "company",
                          id: "company-dropdown"
                        }}
                      >
                        <MenuItem value="" />
                        {showAllCompanies === true
                          ? allCompaniesSorted.map(company => (
                            <MenuItem key={company.id} value={company.id}>
                              {company.name}
                            </MenuItem>
                          ))
                          : userCompaniesSorted.map(company => (
                            <MenuItem key={company.id} value={company.id}>
                              {company.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>

                  {/* Job role  */}
                  <div className="col-md-6 col-sm-12 p-0">
                    <TextField
                      type="text"
                      fullWidth={true}
                      label="Job role"
                      name="jobRole"
                      value={jobRole}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                {/* Email */}
                <div className="row">
                  <div className="col p-0">
                    <TextField
                      type="email"
                      fullWidth={true}
                      label="Email"
                      name="email"
                      value={email}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                {/* Phone */}
                <div className="row">
                  <div className="col mt-2 p-0">
                    <TextField
                      type="text"
                      fullWidth={true}
                      label="Phone"
                      name="phone"
                      value={phone}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                {/* If showing more, render additional input fields (city, state, zip, etc...) */}
                {this.state.showMoreFields ? this.moreFields() : null}

                {/* Notes */}
                <div className="row">
                  <div className="col mt-2 p-0">
                    <TextField
                      type="text"
                      multiline={true}
                      fullWidth={true}
                      label="Notes"
                      name="note"
                      value={note}
                      onChange={this.onChange}
                      rows="6"
                    />
                  </div>
                </div>

                {/* Show more button */}
                <div className="row">
                  <div className="col mt-2 p-0">
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() =>
                        this.setState({
                          showMoreFields: !this.state.showMoreFields
                        })
                      }
                    >
                      {this.state.showMoreFields
                        ? "Less fields"
                        : "More fields"}
                    </button>
                  </div>
                </div>
              </div>

              {/* If showing more, render additional input fields (city, state, zip, etc...) */}
              {updatingContact ? (
                <div className="float-left align-self-end mt-auto">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.onDeleteClick}
                  >
                    Delete
                  </Button>
                </div>
              ) : null}

              <div className="float-right mb-4">
                {/* Prospect toggle */}
                <FormControlLabel
                  label="Prospect"
                  control={
                    <Switch
                      checked={isProspect}
                      onChange={this.onToggle}
                      name="isProspect"
                      value={isProspect}
                      color="primary"
                    />
                  }
                />

                {/* Cancel button */}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </Button>

                {/* Save button */}
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fragment>
    );
  }
}

ContactDetails.propTypes = {
  contact: PropTypes.object,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default compose(
  firestoreConnect(),
  connect(state => ({
    auth: state.firebase.auth,
    userCompanies: state.firestore.ordered.userCompanies,
    allCompanies: state.firestore.ordered.companies,
    users: state.firestore.ordered.users,
    userRole: state.firestore.data.userRole
  }))
)(ContactDetails);
