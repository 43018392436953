import { GET_COMPANIES } from "./actions";
import { firestore } from "../store";

export const getCompanies = () => async (dispatch) => {
    const companies = [];
    firestore.collection("companies").get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const company = {
                id: doc.id,
                ...data
            }

            companies.push(company);
        });
    });

    dispatch({ type: GET_COMPANIES, payload: companies });

    return companies;
}