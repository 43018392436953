import { ADD_CONTACT, GET_CONTACTS, GET_ALL_CONTACTS } from "./actions";
import { firestore } from "../store";
import { getCompanies } from "./companyActions";

export const getContacts = (allContacts = false) => async (dispatch, getState) => {
    const companies = await dispatch(getCompanies());

    const contacts = [];
    // Get our contacts from firebase firestore
    firestore.collection("contacts").get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const contact = {
                id: doc.id,
                ...data
            }

            contacts.push(contact);
        });

        // This adds the "companyName" property to each contact. 🤯
        contacts.map(contact => {
            // if "contact" does not have a "companyName" key, add one
            if (contact.company && !contact.companyName) {
                companies.map(company => {
                    if (contact.company === company.id) {
                        contact.companyName = company.name;
                    }
                });
            }
        });
    })

    dispatch({ type: GET_CONTACTS, payload: contacts });

    return contacts;
};