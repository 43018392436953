import React, { Component } from 'react';

import { allActivityReport } from "../../redux/actions/reportActions";

import { Button, Tooltip } from "@material-ui/core/";

class ReportsPage extends Component {
  onClick = includeSystemActivity => {
    allActivityReport(includeSystemActivity)
  }

  render() {
    return (
      <div className="m-2">
        <Tooltip title="Download all sales activity">
          <Button variant="contained" onClick={() => this.onClick(true)}>
            All sales activity
          </Button>
        </Tooltip>
        <br />
        <div className="mt-2">
          <Tooltip title="Download all sales activity for all users (excluding System activities)">
            <Button variant="contained" onClick={() => this.onClick(false)}>
              All user sales activity
          </Button>
          </Tooltip>
        </div>
      </div>
    )
  }
}

export default ReportsPage;
