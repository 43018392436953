import { CREATE_NOTIFICATION } from "../actions/actions";

const initialState = {
  message: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_NOTIFICATION:
      return {
        ...state,
        message: action.payload
      };
    default:
      return state;
  }
}
