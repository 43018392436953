import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default () => {
  return (
    <div className="d-flex flex-row">
      <div className="flex-column mt-2 mx-auto">
        <CircularProgress mode="indeterminate" size={80} />
      </div>
    </div>
  );
};

//import spinner from "./spinner.gif";

/* <img
        src={spinner}
        alt="Loading..."
        style={{ width: '200px', margin: 'auto', display: 'block' }}
      />
 */
