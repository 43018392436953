import React, { Component } from "react";
import PropTypes from "prop-types";
// import { compose } from "redux";
// import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Card from "../common/Card";
import { createNotification } from "../common/createNotification";

class Login extends Component {
  state = {
    email: "",
    password: ""
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  onSubmit = e => {
    e.preventDefault();
    const { firebase } = this.props;
    const { email, password } = this.state;

    firebase
      .login({
        email,
        password
      })
      .then(createNotification("Login successful."))
      .catch(err => {
        createNotification(err.message);
      });
  };

  render() {
    return (
      <div className="w-25 mx-auto mt-2">
        <Card title={"Login"} centerTitle={true}>
          <form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col px-2 py-0">
                <TextField
                  type="email"
                  fullWidth={true}
                  label="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col px-2 py-0">
                <TextField
                  type="password"
                  fullWidth={true}
                  label="Password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col px-2 py-0">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth={true}
                >
                  Login
                </Button>
              </div>
            </div>
          </form>
        </Card>
      </div>
    );
  }
}

Login.propTypes = {
  firebase: PropTypes.object.isRequired
};

export default firebaseConnect()(Login);
