import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import PropTypes from "prop-types";
import moment from "moment";

import CompanyDetails from "./CompanyDetails";
import { createNotification } from "../common/createNotification";

class EditCompany extends Component {
  onDelete = () => {
    const { company, firestore } = this.props;

    firestore
      .delete({ collection: "companies", doc: company.id })
      .then(
        createNotification(`${company.name} has been deleted.`),
        this.props.closeDetails()
      );
  };

  onSave = updatedCompany => {
    const { auth, company, firestore } = this.props;

    if (updatedCompany !== company) {
      updatedCompany.lastModified = moment().format("MM/DD/YYYY h:mm:ss a");
      updatedCompany.lastModifiedBy = auth.uid;
      firestore
        .update({ collection: "companies", doc: company.id }, updatedCompany)
        .then(
          createNotification(`${updatedCompany.name} has been updated.`),
          this.props.closeDetails()
        );
    } else {
      createNotification(`No changes were made to ${company.name}.`);
      this.props.closeDetails();
    }
  };

  render() {
    return (
      <Fragment>
        <CompanyDetails
          company={this.props.company}
          onDelete={this.onDelete}
          onCancel={this.props.closeDetails}
          onSave={this.onSave}
          updatingCompany={true}
        />
      </Fragment>
    );
  }
}

EditCompany.propTypes = {
  firestore: PropTypes.object.isRequired,
  editTargetId: PropTypes.string.isRequired
};

export default compose(
  firestoreConnect(props => [
    {
      collection: "companies",
      storeAs: "company",
      doc: props.editTargetId
    }
  ]),
  connect(({ firestore: { ordered } }) => ({
    company: ordered.company && ordered.company[0]
  })),
  connect((state, props) => ({
    auth: state.firebase.auth
  }))
)(EditCompany);
