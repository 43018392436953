import { ADD_COMPANY, GET_COMPANIES, GET_ALL_COMPANIES, UPDATE_COMPANY } from "../actions/actions";

const initialState = {
    allCompanies: [],
    userCompanies: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_COMPANIES:
            return {
                ...state,
                userCompanies: action.payload,
            };
        case GET_ALL_COMPANIES:
            return {
                ...state,
                allCompanies: action.payload,
            }
        default:
            return state;
    }
}
