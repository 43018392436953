import firebase from "firebase";
import { firestore } from "../store";
import xlsx from 'xlsx';

export async function allActivityReport(includeSystemActivity = true) {
  // make sure the user is authenticated
  if (firebase.auth().currentUser.uid) {
    firestore.collection("userRoles").doc(firebase.auth().currentUser.uid).get().then(async function (doc) {
      const user = doc.data();

      // only users with the role 'admin' can view this report
      if (user.role === "admin") {
        // sales activity
        const salesActivity = [];
        await firestore.collection("salesActivity").get().then(function (querySnapshot) {
          querySnapshot.forEach(doc => {
            const data = doc.data();
            const activity = {
              id: doc.id,
              ...data
            }

            salesActivity.push(activity);
          });
        });

        // companies
        const companies = [];
        await firestore.collection("companies").get().then(function (querySnapshot) {
          querySnapshot.forEach(doc => {
            const data = doc.data();
            const company = {
              id: doc.id,
              ...data
            };

            companies.push(company);
          });
        });

        // contacts
        const contacts = [];
        await firestore.collection("contacts").get().then(function (querySnapshot) {
          querySnapshot.forEach(doc => {
            const data = doc.data();
            const contact = {
              id: doc.id,
              ...data
            };

            contacts.push(contact);
          });
        });

        // users
        const users = [];
        await firestore.collection("users").get().then(function (querySnapshot) {
          querySnapshot.forEach(doc => {
            const data = doc.data();
            const user = {
              id: doc.id,
              ...data
            };

            users.push(user);
          })
        })

        let joinedActivities = [];

        if (includeSystemActivity) {
          salesActivity.forEach(activity => {
            let joinedActivity = activity;

            contacts.forEach(contact => {
              if (activity.prospect === contact.id) {
                joinedActivity.prospect = contact.displayName;

                if (contact.company) {
                  companies.forEach(company => {
                    if (contact.company === company.id) {
                      joinedActivity.company = company.name;
                    }
                  })
                }
              }
            })

            users.forEach(user => {
              if (activity.salesperson === user.id) {
                joinedActivity.salesperson = user.displayName;
              }
            })

            delete joinedActivity.id;
            delete joinedActivity.createdAt;
            delete joinedActivity.createdById;

            joinedActivities.push(joinedActivity);
          });
        } else {
          salesActivity.forEach(activity => {
            if (activity.createdBy !== "System") {
              let joinedActivity = activity;

              contacts.forEach(contact => {
                if (activity.prospect === contact.id) {
                  joinedActivity.prospect = contact.displayName;

                  if (contact.company) {
                    companies.forEach(company => {
                      if (contact.company === company.id) {
                        joinedActivity.company = company.name;
                      }
                    })
                  }
                }
              })

              users.forEach(user => {
                if (activity.salesperson === user.id) {
                  joinedActivity.salesperson = user.displayName;
                }
              })

              delete joinedActivity.id;
              delete joinedActivity.createdAt;
              delete joinedActivity.createdById;

              joinedActivities.push(joinedActivity);
            }
          });
        }

        // this creates the excel file and downloads it
        let XLSX = xlsx;

        let workSheet = XLSX.utils.json_to_sheet(
          joinedActivities,
          {
            header: ["company", "prospect", "salesperson", "justDone", "activityType", "nextAction", "nextActivityType", "nextActivityDate",
              "salesStatus", "outlook", "projectedCloseDate", "projectedSaleAmount", "reasonForDead", "saleAmount", "soldDate", "created",
              "createdBy"
            ]
          }
        );
        let workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet);
        if (includeSystemActivity) {
          XLSX.writeFile(workBook, "AllSalesActivity.xlsx");
        } else {
          XLSX.writeFile(workBook, "UserSalesActivity.xlsx");
        }

        return joinedActivities;
      }
    });
  }
}