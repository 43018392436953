import React, { Component, Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { firestoreConnect } from "react-redux-firebase";
import { createNotification } from "../common/createNotification";

export class Navigation extends Component {
  state = {
    isAuthenticated: false
  };

  static getDerivedStateFromProps(props, state) {
    const { auth } = props;

    if (auth.uid) {
      return { isAuthenticated: true };
    } else {
      return { isAuthenticated: false };
    }
  }

  onLogoutClick = e => {
    e.preventDefault();
    const { firebase } = this.props;
    firebase.logout().then(createNotification("Logged out successfully."));
  };

  render() {
    const { isAuthenticated } = this.state;

    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={this.props.logo} alt={this.props.branding} width="240" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarToggler">
            <ul className="navbar-nav ml-auto">
              {isAuthenticated ? (
                <Fragment>
                  {" "}
                  <li className="nav-item mr-2">
                    <NavLink to="/dashboard" className="nav-link">
                      <i className="far fa-home" /> Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item mr-2">
                    <NavLink to="/contacts" className="nav-link">
                      <i className="far fa-address-book" /> Contacts
                    </NavLink>
                  </li>
                  <li className="nav-item mr-2">
                    <NavLink to="/companies" className="nav-link">
                      <i className="fal fa-building" /> Companies
                    </NavLink>
                  </li>
                  {this.props.userRole !== undefined &&
                    this.props.userRole[0].role === "admin"
                    ? (<li className="nav-item mr-2">
                      <NavLink to="/reports" className="nav-link">
                        <i className="far fa-chart-area" /> Reports
                    </NavLink>
                    </li>)
                    : null}
                  <li className="nav-item dropdown mr-2">
                    <button
                      className="btn btn-link nav-link dropdown-toggle"
                      id="navbarDropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {this.props.auth.email}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <button
                        className="btn btn-link dropdown-item"
                        onClick={this.onLogoutClick}
                      >
                        Logout
                      </button>
                    </div>
                  </li>
                </Fragment>
              ) : null}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

Navigation.defaultProps = {
  branding: "PACManager",
  logo: `${process.env.PUBLIC_URL}/logo.png`
};

Navigation.propTypes = {
  firebase: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

export default compose(
  firebaseConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth,
    userRole: state.firestore.ordered.userRole
  })),
  firestoreConnect(props => [
    {
      collection: "userRoles",
      storeAs: "userRole",
      doc: props.auth.uid
    }
  ])
)(Navigation);
