import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import ContactsDrilldownToolbar from "../common/ContactsDrilldownToolbar";

import { actions } from "../common/Actions";
import AddContactDetails from "./AddContactDetails";
import EditContactDetails from "./EditContactDetails";

const columns = [
  {
    name: "id",
    label: "id",
    options: {
      display: "excluded"
    }
  },
  {
    name: "displayName",
    label: "Name",
    options: {
      sort: true,
      sortDirection: 'asc'
    }
  },
  {
    name: "companyName",
    label: "Company",
    options: {
      sort: true
    }
  },
  {
    name: "email",
    label: "Email",
    options: {
      sort: true
    }
  },
  {
    name: "phone",
    label: "Phone",
    options: {
      sort: true
    }
  },
  {
    name: "lastModified",
    label: "Last Modified",
    options: {
      display: "false",
      sort: true
    }
  }
];

class ContactsDrilldown extends Component {
  state = {
    action: undefined,
    allContacts: [],
    userContacts: [],
    showDetails: false,
    updateTargetId: undefined,

    showAllContacts: false
  };

  mapCompaniesToContacts = (companies, contacts) => {
    const mappedList = [];

    if (contacts && companies) {
      contacts.map(contact => {
        let newContact = {
          ...contact
        };
        if (newContact.company && !newContact.companyName) {
          companies.map(company => {
            if (newContact.company === company.id) {
              newContact.companyName = company.name;
            }
          })
        } else if (!newContact.company && newContact.companyName) {
          newContact.companyName = "";
        }

        mappedList.push(newContact);
      })

      return mappedList;
    }
  }

  componentDidMount() {
    const { allCompanies, allContacts, userCompanies, userContacts } = this.props;

    this.setState({
      allContacts: this.mapCompaniesToContacts(allCompanies, allContacts),
      userContacts: this.mapCompaniesToContacts(userCompanies, userContacts)
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      const { allCompanies, allContacts, userCompanies, userContacts } = this.props;

      this.setState({
        allContacts: this.mapCompaniesToContacts(allCompanies, allContacts),
        userContacts: this.mapCompaniesToContacts(userCompanies, userContacts)
      })
    }
  }

  openDetails = action => {
    this.setState({ showDetails: true, action });
  };

  closeDetails = () => {
    this.setState({ showDetails: false });
  };

  onRowClick = rowData => {
    this.setState({ updateTargetId: rowData[0] });
    this.openDetails(actions.EDIT);
  };

  onCheck = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  options = {
    selectableRows: "none",
    pagination: true,
    filterType: "none",
    print: false,
    download: false,
    responsive: "scroll",
    onRowClick: this.onRowClick,
    elevation: 1,
    customToolbar: () => {
      return (
        <ContactsDrilldownToolbar
          onCheck={() =>
            this.setState({ showAllContacts: !this.state.showAllContacts })
          }
          onClick={() => this.openDetails(actions.ADD)}
        />
      );
    }
  };

  render() {
    const { showDetails } = this.state;

    return (
      <div className="row no-gutters h-100">
        <div className="col-md-6 col-sm-12 h-100">
          <div className="mt-2 mx-2">
            <MUIDataTable
              title={"Contacts"}
              data={
                this.state.showAllContacts
                  ? this.state.allContacts
                  : this.state.userContacts
              }
              columns={columns}
              options={this.options}
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12 h-100">
          <div className="mt-2 mx-2">
            {showDetails ? (
              <Fragment>
                {
                  {
                    [actions.ADD]: (
                      <AddContactDetails
                        closeDetails={this.closeDetails}
                        showAllCompanies={this.state.showAllContacts}
                      />
                    ),
                    [actions.EDIT]: (
                      <EditContactDetails
                        targetContactId={this.state.updateTargetId}
                        closeDetails={this.closeDetails}
                        showAllCompanies={this.state.showAllContacts}
                      />
                    )
                  }[this.state.action]
                }
              </Fragment>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  auth: state.firebase.auth,
  userCompanies: state.firestore.ordered.userCompanies,
  userContacts: state.firestore.ordered.userContacts,
  allCompanies: state.firestore.ordered.companies,
  allContacts: state.firestore.ordered.contacts,
  userRole: state.firestore.data.userRole
}))(ContactsDrilldown);
