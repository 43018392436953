import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { createNotification } from "../common/createNotification";
import ContactDetails from "./ContactDetails";
import moment from "moment";

import { addActivity } from "../../redux/actions/salesActivityActions";
import { salesStatuses } from "../sales/salesStatuses";

class AddContactDetails extends Component {
  onSave = contact => {
    const { auth, addActivity, firestore } = this.props;
    const newContact = contact;
    newContact.createdDate = moment().format();
    newContact.lastModified = moment().format("MM/DD/YYYY h:mm:ss a");
    newContact.createdBy = auth.uid;
    newContact.lastModifiedBy = auth.uid;
    if (contact.salesperson == null) {
      newContact.salesperson = auth.uid;
    }

    firestore
      .add({ collection: "contacts" }, newContact)
      .then(function (docRef) {
        // if the contact is now a prospect, add an activity and mark them as prospect.
        if (newContact.isProspect) {
          const newActivity = {
            // docRef.id is the newly created contact's id.
            prospect: docRef.id,
            justDone: `${contact.firstName} ${contact.lastName} has been made a prospect.`,
            salesStatus: salesStatuses.PROSPECT,
            createdBy: "System",
            createdAt: moment().format("MM/DD/YYYY"),
            created: moment().format()
          };

          addActivity(newActivity, docRef.id);
        }
      })
      .then(
        createNotification(
          `${contact.firstName} ${contact.lastName} has been added.`
        ),
        this.props.closeDetails()
      );
  };

  render() {
    return (
      <ContactDetails
        onCancel={this.props.closeDetails}
        onSave={this.onSave}
        showAllCompanies={this.props.showAllCompanies}
      />
    );
  }
}

AddContactDetails.propTypes = {
  firestore: PropTypes.object.isRequired
};

export default compose(
  firestoreConnect(),
  connect(state => ({
    auth: state.firebase.auth,
    userRole: state.firestore.data.userRole.role
  }), { addActivity })
)(AddContactDetails);
