import React from "react";
import PropTypes from "prop-types";
import MaterialCard from "@material-ui/core/Card";
import MaterialCardContent from "@material-ui/core/CardContent";

const Card = props => {
  return (
    <MaterialCard>
      {props.centerTitle ? (
        <div>
          <h6 className="cardTitleCentered">{props.title}</h6>
        </div>
      ) : (
        <div>
          <h6 className="cardTitle">{props.title}</h6>
        </div>
      )}
      <MaterialCardContent className="pt-0">
        {props.children}
      </MaterialCardContent>
    </MaterialCard>
  );
};

Card.defaultProps = {
  centerTitle: false
};

Card.propTypes = {
  title: PropTypes.string,
  centerTitle: PropTypes.bool
};

export default Card;
